// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Lakindu Kariyawasam",
  title: "Hi, I'm Lakindu",
  subTitle: emoji(
    "A passionate Software Engineer from Sri Lanka 🚀 having an experience of building Web and Mobile applications with JavaScript and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/drive/folders/1RjtuTB3p0uE_N0AlrD8X8g_ZP6J-vFLX?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/LakinduK",
  linkedin: "https://www.linkedin.com/in/lakinduk/",
  gmail: "lakindu1997@gmail.com",
  facebook: "https://www.facebook.com/lakindu.kariyawasam/",
  medium: "https://lakinduk.medium.com/",
  stackoverflow: "https://stackoverflow.com/users/10171342/lakindu-kariyawasam",
  instagram: "https://www.instagram.com/lakindu_yk",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do 👀",
  subTitle:
    " Im an IT enthusiast, eager to try new technologies, good in problem solving, Excelent in teamwork management & communicating.",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Mobile-First, Responsive Design"),
    emoji("⚡ Desktop Applications Developing & Debugging")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "bootstrap",
      fontAwesomeClassname: "fab fa-bootstrap"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "Flutter",
      fontAwesomeClassname: "fab fa-flutter"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "laravel",
      fontAwesomeClassname: "fab fa-laravel"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "wordpress",
      fontAwesomeClassname: "fab fa-wordpress"
    },
    {
      skillName: "android",
      fontAwesomeClassname: "fab fa-android"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },

    {
      skillName: ".NET",
      fontAwesomeClassname: "fab fa-microsoft"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Plymouth - UK",
      logo: require("./assets/images/plymouthLogo.png"),
      subHeader: "Bachelor of Science in Software Engineering",
      duration: "Mar 2018 - Dec 2021",
      desc: "Graduated with a First Class Honors | Aggregate Final Mark : 70.27",
      descBullets: [
        "Community Member and volunteer of FOSS Sri Lanka",
        "Community Member of Mozilla Sri Lanka",
        "Community Member of FOSS NSBM"
      ]
    },
    {
      schoolName: "Sri Sumangala College - Panadura",
      logo: require("./assets/images/sumangalaLogo.png"),
      subHeader: "Successfully Completed High School",
      duration: "Jan 2007 - Dec 2016",
      desc: "",
      descBullets: [
        "General Certificate of Education (G.C.E) Advanced level in Science Stream. (2015)",
        "General Certificate of Education (G.C.E) Ordinary levels. (2012)"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "70%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "80%"
    },
    {
      Stack: "Programming",
      progressPercentage: "90%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Software Engineer",
      company: "Techno Global Team",
      companylogo: require("./assets/images/TgtLogo.jpg"),
      date: "Feb 2025 - Present",
      desc: " Since 1993, Techno Global Inc. has been a technology company who has been delivering Enterprise solutions oriented towards Operations, Sales, Marketing and Customer Service.",
      descBullets: [
        "Experience with ASP.NET Core 7/8, Blazor, and ASP.NET MVC framework.",
        "Strong knowledge of design patterns and SOLID principles.",
        "Skilled in TDD (Test-Driven Development) practices.",
        "Familiar with Azure DevOps for version control, continuous integration, and deployment.",
        "Experienced in Agile development environments, using Jira or similar project management tools."
      ]
    },
    {
      role: "Senior Software Engineer",
      company: "RELMS",
      companylogo: require("./assets/images/RelmsLogo.png"),
      date: "Sep 2023 - Jan 2025",
      desc: " RELMS is an innovative software startup based in Australia, specializing in developing robust and cutting-edge solutions for the global marketplace.",
      descBullets: [
        "Lead development efforts by guiding junior developers and ensuring adherence to coding standards and best practices.",
        "Design Blazor-based web applications, ensuring scalability, performance, and security.",
        "Collaborate with cross-functional teams (UI/UX designers, product managers) to deliver high-quality features.",
        "Integrate backend services and APIs using .NET Core and Entity Framework with Blazor front-end.",
        "Handle DevOps tasks such as CI/CD pipeline configurations, deployment automation, and environment setup (e.g., Azure)."
      ]
    },
    {
      role: "Software Engineer",
      company: "Virtusa",
      companylogo: require("./assets/images/VirtusaLogo.jpg"),
      date: "Oct 2022 - Sep 2023",
      desc: "Virtusa has a worldwide footprint with offices and delivery centers in the Americas, Asia, Europe, and the Middle East. ",
      descBullets: [
        "End to end business use case development on Pega platform 8.6",
        "Unit testing and adoption of agile methodologies throughout SDLC ",
        "Pega deployment practices, mentor and coach junior team members ",
        "Improvement of technical skills via R&D of additional Pega",
        "Improvement of technical skills via R&D of additional Pega frameworks and certifications"
      ]
    },
    {
      role: "Associate Software Engineer",
      company: "Virtusa",
      companylogo: require("./assets/images/VirtusaLogo.jpg"),
      date: "Sept 2021 - Sep 2022",
      desc: "Virtusa has a worldwide footprint with offices and delivery centers in the Americas, Asia, Europe, and the Middle East. ",
      descBullets: [
        "Certified PEGA PCSA 8.0 Developer",
        "Build solutions using Java-based business process management tool known as PEGA. "
      ]
    },
    {
      role: "Kids Robotics Lecturer",
      company: "SLIR",
      companylogo: require("./assets/images/slir2Logo.png"),
      date: "Mar 2021 - Oct 2021",
      desc: "Sri Lanka Institute of Robotics (SLIR) is Sri Lanka's one and only institution which offers Kids Robotics, Beginner to Advanced Robotics and PLC Courses."
    },
    {
      role: "Trainee Telecommunication Technician",
      company: "Fentons LTD",
      companylogo: require("./assets/images/fentonsLogo.png"),
      date: "Mar 2017 – Feb 2018",
      desc: "1 year experience of working as a telecommunication technician in Fentons Limited by Hayles.",
      descBullets: [
        "Trained to install, configure and troubleshoot large scale intercom systems",
        "worked as a member of installation team."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Featured Projects ⭐",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/syncspence.png"),
      projectName: "Syncspence",
      projectDesc:
        " SyncSpence is an expense management app, designed to synchronize, track, and manage expenses effortlessly. ",
      footerLink: [
        {
          name: "PlayStore link",
          url: "https://play.google.com/store/apps/details?id=com.syncspence"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/SalonStylo.png"),
      projectName: "Salon Stylo",
      projectDesc:
        " A single page website made for a local salon called 'Salon Stylo.' ",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://lakinduk.me/stylo"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/sarigama.png"),
      projectName: "Sarigama - සරිගම Discord Music Bot",
      projectDesc:
        " Discord music bot made with python and YoutubeDL libraries to play music on discord.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://lakinduk.me/sarigama/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/theresearchtech.png"),
      projectName: "theResearch.tech",
      projectDesc:
        "A collaborative working Platform for Online Researches & Publications",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://www.theresearch.tech/"
        },
        {
          name: "Presentation slide",
          url: "https://slides.com/lakinduk/theresearch"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/helaosu.png"),
      projectName: "helaOsu - හෙළඔසු",
      projectDesc:
        "An ayurvedic recipe management system built using laravel PHP, React, mysql with distributed system architecture",
      footerLink: [
        {
          name: "project",
          url: "https://github.com/LakinduK/helaosu"
        },
        {
          name: "Presentation slide",
          url: "https://slides.com/lakinduk/helaosu"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Pega Certified System Architect 8.0",
      subtitle:
        "Certified Pega System Architect certification proves basic proficiency in the design and construction of Pega applications.",
      image: require("./assets/images/PegaPCSA.png"),
      footerLink: [
        {
          name: "certificate",
          url: "https://drive.google.com/file/d/1jySNt1B-CmpMcO7LuMXPgmR7KfI8otOe/view?usp=sharing"
        }
      ]
    },
    {
      title: "Google Hashcode Competition ",
      subtitle:
        "Google Hashcode is one of the Google's largest hackathons. This is the certificate of accomplishments in 2020 and 2019",
      image: require("./assets/images/hashcode.png"),
      footerLink: [
        {
          name: "2020 certificate",
          url: "https://drive.google.com/file/d/1QunHf7uXieOQ8eFdRnoHHk4H7wKTnc5_/view?usp=sharing"
        },
        {
          name: "2019 certificate",
          url: "https://drive.google.com/file/d/1gtnTwRBUwfyJFKNBkuhwGxHd46euYZqg/view?usp=sharing"
        }
      ]
    },
    {
      title: "Kubernetes in Google Cloud - Google Badge",
      subtitle:
        "Kubernetes is an open-source container-orchestration system for automating computer application deployment, scaling, and management.",
      image: require("./assets/images/kubernetes.png"),
      footerLink: [
        {
          name: "Google Badge",
          url: "https://www.qwiklabs.com/public_profiles/c33e0a96-3b09-48ab-ba9a-7cf3558c1ccb"
        }
      ]
    },
    {
      title: "cPanel Professional Certification (CPP)",
      subtitle: "Completed CPP Certifcation from cPanel.net",
      image: require("./assets/images/cPanel.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/140sGE3MeSNnGvL8pI_9k7j50nQU2-Ljd/view?usp=sharing"
        }
      ]
    },
    {
      title: "Choosing a Cloud Platform for Developers",
      subtitle:
        "Completed Choosing a Cloud Platform for Developers: AWS, Azure, and GCP Certifcation from LinkedIn Learning",
      image: require("./assets/images/linkedincertificateLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.linkedin.com/learning/certificates/b0d67552580474e37755983fc929916ca126bcaa61251d52d109ad84b6e411dc?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3BldxY7aEnRKCcDZZGJ8KarQ%3D%3D"
        },
        {
          name: "Course",
          url: "https://www.linkedin.com/learning/choosing-a-cloud-platform-for-developers-aws-azure-and-gcp/considering-the-cloud-choices?autoplay=true"
        }
      ]
    },
    {
      title: "C++ Certified",
      subtitle: "Completed C++ Certifcation from LinkedIn Learning",
      image: require("./assets/images/linkedincertificateLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.linkedin.com/learning/certificates/8518664e26b612059cf2351faabaa245ffb5af7483b54d6a42174e6f6e1f1c47?trk=share_certificate"
        },
        {
          name: "Course",
          url: "https://www.linkedin.com/learning/learning-c-plus-plus-3/fun-with-c-plus-plus"
        }
      ]
    },
    {
      title: "Node Package Manager NPM",
      subtitle:
        "Completed Learning npm the Node Package Manager Certifcation from LinkedIn Learning",
      image: require("./assets/images/linkedincertificateLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.linkedin.com/learning/certificates/a85d7904bcf6b251ab2ee164990936bff632abbfb3c13c80859e3b02d168f91e?trk=share_certificate"
        },
        {
          name: "Course",
          url: "https://www.linkedin.com/learning/learning-npm-the-node-package-manager-3/"
        }
      ]
    },
    {
      title: "Learning ECMAScript 6+ (ES6+)",
      subtitle:
        "Completed Learning ECMAScript 6+ (ES6+) Certifcation from LinkedIn Learning",
      image: require("./assets/images/linkedincertificateLogo.png"),
      footerLink: [
        {
          name: "Certification",
          url: "https://www.linkedin.com/learning/certificates/e6c25e6bf79bf815fe74e1d5211992f8d476fdbe8c3ab32f55f8992a6d5d48cb?trk=share_certificate"
        },
        {
          name: "Course",
          url: "https://www.linkedin.com/learning/learning-ecmascript-6-plus-es6-plus/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs 📑",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://lakinduk.medium.com/20-essential-flutter-tips-and-tricks-for-better-development-564328609f78",
      title: "20 Essential Flutter Tips and Tricks for Better Development 🧑‍💻",
      description:
        "When working with Flutter, developers often encounter challenges that can slow down the development process...",
      isLatest: true
    },
    {
      url: "https://lakinduk.medium.com/how-to-add-vim-to-visual-studio-code-to-speed-up-your-coding-1cd7e02c78ec",
      title: "How to add Vim to Visual Studio Code to speed up your coding!",
      description:
        "Vim is a powerful keyboard-based text editor that enables developers to code faster without lifting their hands off the keyboard...",
      isLatest: false
    },
    {
      url: "https://lakinduk.medium.com/08-visual-studio-code-extensions-that-speed-up-your-workflow-2021-6704fab543d2",
      title: "Visual Studio Code Extensions that speed up your workflow — 2021",
      description:
        "Everyone uses Visual Studio Code these days for their day-to-day coding because it is fast, super light, reliable and...",
      isLatest: false
    },
    {
      url: "https://slides.com/lakinduk/c-programming",
      title: "Introduction to C programming",
      description:
        "A beginner guide to understand C programming language concepts and theories.",
      isLatest: false
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// services Section

const servicesSection = {
  title: "Free Services 👐",
  subtitle:
    "Some of the free services that I provide to help people to learn and grow.",

  services: [
    {
      url: "https://lakinduk.github.io/ict-words",
      title: "ICT Words Glossary",
      description:
        "A Sinhala to English ICT words glossary for O/L ICT students ",
      isLatest: true
    },
    {
      url: "https://lakinduk.github.io/sinhala",
      title: "Realtime Sinhala Unicode Converter",
      description:
        "Struggling to type Sinhala words in Photoshop etc? Use this tool to convert English to Sinhala Unicode",
      isLatest: false
    },
    {
      url: "https://lakinduk.github.io/BMI3D",
      title: "BMI 3D Calculator",
      description:
        "Calculate your BMI value according to your height and weight.",
      isLatest: false
    },
    {
      url: "https://lakinduk.github.io/hacktoberfest2022/contributors",
      title: "Hacktoberfest contributors - 2022",
      description:
        "Motivate people to Open Source contributions by using Github",
      isLatest: false
    },
    {
      url: "https://lakinduk.github.io/blog",
      title: "Simple blog",
      description:
        "Simple blog website template to learn basic web development using javascript",
      isLatest: false
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS and EVENTS 🧙‍♂️",
  subtitle: emoji("I LOVE TO SHARE MY LIMITED KNOWLEDGE WITH PEOPLE 😅"),

  talks: [
    {
      title: "Scratch-Create Games Without Coding",
      subtitle: "Scratch is an interactive block-based programming language..",
      slides_url: "https://slides.com/lakinduk/scratch",
      event_url:
        "https://www.facebook.com/dinith.malaka/posts/pfbid021ZYAdWa9S5f4vrQQciod8XBph2jssqyqsCR2FNQ2LzT9Nd6nGNPGud3ELeZrKWbUl"
    },
    {
      title: "Dive Into C (2020) -  C Programming",
      subtitle: "A beginner guide to understand C programming language..",
      slides_url: "https://slides.com/lakinduk/c-programming",
      event_url: "https://fb.me/e/GlvoJBCP"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+94767372105",
  email_address: "lakindu1997@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "k_lakindu", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  servicesSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
